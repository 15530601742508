import * as React from "react"
export default function PoweredByVidCruiter() {
    return (
        <footer>
            <div className={"py3 "} style={{width: "100%", background: "#000000", color: "#ffffff"}}>
                <p className={"mb-0 container text-center"} style={{
                    width: "100%", color: "#ffffff", fontSize: 16
                }}>Powered By <a
                    href={"https://vidcruiter.com/"}
                    style={{width: "100%", fontSize: 16, color: "#ffffff"}} rel="nofollow noopener noreferrer"
                    target={"_blank"}><u>VidCruiter</u></a></p>
            </div>
        </footer>)
};
