import * as React from "react";

import image1 from "../../images/CMC Copy.png";
import image2 from "../../images/Season and Shell Store View Copy.png";
import image3 from "../../images/Employee 3 Copy.png";

import cornerMarketCafeLogo from "../../images/Seasons-Corner-Cafe-logo.png";
import seasonsLogo from "../../images/season-logo.png";
import eastSideLogo from "../../images/eastside-logo.jpg";

import PoweredByVidCruiter from "./PoweredByVidCruiter";

let linkStyle = {
  color: "rgb(0, 140, 186)",
  textDecoration: "none",
  paddingTop: 0,
  paddingRight: 10,
  cursor: "pointer",
  fontSize: 18,
};

let parentLinkStyle = {
  color: "rgb(0, 140, 186)",
  textDecoration: "none",
  paddingTop: 0,
  paddingRight: 10,
  cursor: "pointer",
  fontSize: 22,
  fontWeight: 700,
};
export default function Footer(props) {
  return (
    <footer>
      <div style={{ background: "#23A0D8", padding: "0px 4px" }}>
        <div className={"r jc ac"}>
          <h2
            className={"cw mb0 mt5"}
            style={{ fontSize: 32, lineHeight: "38px", textAlign: "center" }}
          >
            Customer Service Is Our Goal
          </h2>
        </div>
        <div
          className={"r jc ac fww mxa"}
          style={{ transform: "translateY(30px)", marginBottom: "6rem" }}
        >
          <img
            src={image1}
            className={"mw100 mt4 mx3 bot-img"}
            width={"300"}
            alt={"image1"}
          />
          <img
            src={image2}
            className={"mw100 mt4 mx3 bot-img"}
            width={"300"}
            alt={"image2"}
          />
          <img
            src={image3}
            className={"mw100 mt4 mx3 bot-img"}
            width={"300"}
            alt={"image3"}
          />
        </div>
      </div>

      <div className={"c jc ac mb5 mt4"}>
        <a
          href={"http://www.eastsideenterprise.com/"}
          style={parentLinkStyle}
          target={"_blank"}
          rel={"noreferrer"}
          className={"px3"}
        >
          <img src={eastSideLogo} height={60} className="mb4" />
        </a>
        <a
          href={"http://www.eastsideenterprise.com/"}
          style={parentLinkStyle}
          target={"_blank"}
          rel={"noreferrer"}
          className={"px3"}
        >
          EAST SIDE ENTERPRISE
        </a>
      </div>
      <div
        style={{
          gap: "36px",
          alignItems: "start",
          justifyContent: "center",
        }}
        className="mwxxl pb5 r-c-md ac-md mxa"
      >
        <div className={"c jc ac"}>
          <a
            href={"http://www.seasonscornermarket.com/"}
            style={linkStyle}
            target={"_blank"}
            rel={"noreferrer"}
            className={"mb2 px3"}
          >
            <img
              src={seasonsLogo}
              height={50}
              style={{ marginBottom: "1.5rem", marginTop: "1.6rem" }}
            />
          </a>
          <a
            href={"http://www.seasonscornermarket.com/"}
            style={linkStyle}
            target={"_blank"}
            rel={"noreferrer"}
            className={"mb2 px3"}
          >
            SEASONS CORNER MARKET
          </a>
          <div className={"r mb2 jc ac mxa mt2"} id="">
            <a
              href="https://www.facebook.com/SeasonsCornerMarkets"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-link"
            >
              <svg
                focusable="false"
                height="44px"
                role="presentation"
                width="44px"
                viewBox="0 0 44 44"
                className="sc-bdVaJa hhZVOl"
                type="facebook"
              >
                <circle id="bgCircle" cx="22" cy="22" r="20" />
                <path
                  data-test="facebook"
                  d="M23.34,34V23.07H27l.55-4.28H23.34V16.06c0-1.24.35-2.08,2.12-2.08h2.27V10.17A31.29,31.29,0,0,0,24.44,10C21.18,10,19,12,19,15.64v3.15H15.27v4.28H19V34Z"
                />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/east-side-enterprises-llc/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-link"
            >
              <svg
                focusable="false"
                height="44px"
                role="presentation"
                width="44px"
                viewBox="0 0 44 44"
                className="sc-bdVaJa emrSpz"
                type="linkedin"
              >
                <circle id="bgCircle" cx="22" cy="22" r="20" />
                <path
                  data-test="linkedin"
                  d="M15.37,34h-5V18h5ZM12.88,15.79a2.88,2.88,0,1,1,2.88-2.88A2.88,2.88,0,0,1,12.88,15.79ZM34,34H29V26.2C29,24.34,29,22,26.45,22s-3,2-3,4.11V34h-5V18h4.77v2.19h.07A5.23,5.23,0,0,1,28,17.58c5,0,6,3.31,6,7.62Z"
                />
              </svg>
            </a>
            <a
              href="https://www.youtube.com/SeasonsCornerMarket"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-link"
            >
              <svg
                focusable="false"
                height="44px"
                role="presentation"
                width="44px"
                viewBox="0 0 44 44"
                className="sc-bdVaJa ftlKrm"
                type="youtube"
              >
                <circle id="bgCircle" cx="22" cy="22" r="20"></circle>
                <path
                  data-test="youtube"
                  d="M33.5,16.19a3,3,0,0,0-2.12-2.14c-1.87-.5-9.38-.5-9.38-.5s-7.51,0-9.38.5a3,3,0,0,0-2.12,2.14A31.63,31.63,0,0,0,10,22a31.63,31.63,0,0,0,.5,5.81,3,3,0,0,0,2.12,2.14c1.87.5,9.38.5,9.38.5s7.51,0,9.38-.5a3,3,0,0,0,2.12-2.14A31.64,31.64,0,0,0,34,22,31.63,31.63,0,0,0,33.5,16.19Zm-14,9.38V18.43L25.82,22Z"
                ></path>
              </svg>
            </a>

            <a
              href="https://www.instagram.com/SeasonsCornerMarket/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-link"
            >
              <svg
                focusable="false"
                height="44px"
                role="presentation"
                width="44px"
                viewBox="0 0 44 44"
                className="sc-bdVaJa hKJTVw"
                type="instagram"
              >
                <circle id="bgCircle" cx="22" cy="22" r="20"></circle>
                <g data-test="instagram">
                  <path d="M22,12.16c3.2,0,3.58,0,4.85.07a6.64,6.64,0,0,1,2.23.41,4,4,0,0,1,2.28,2.28,6.64,6.64,0,0,1,.41,2.23c.06,1.27.07,1.64.07,4.85s0,3.58-.07,4.85a6.64,6.64,0,0,1-.41,2.23,4,4,0,0,1-2.28,2.28,6.64,6.64,0,0,1-2.23.41c-1.27.06-1.64.07-4.85.07s-3.58,0-4.85-.07a6.64,6.64,0,0,1-2.23-.41,4,4,0,0,1-2.28-2.28,6.64,6.64,0,0,1-.41-2.23c-.06-1.27-.07-1.64-.07-4.85s0-3.58.07-4.85a6.64,6.64,0,0,1,.41-2.23,4,4,0,0,1,2.28-2.28,6.64,6.64,0,0,1,2.23-.41c1.27-.06,1.64-.07,4.85-.07M22,10c-3.26,0-3.67,0-4.95.07a8.81,8.81,0,0,0-2.91.56,6.14,6.14,0,0,0-3.51,3.51,8.81,8.81,0,0,0-.56,2.91C10,18.33,10,18.74,10,22s0,3.67.07,4.95a8.81,8.81,0,0,0,.56,2.91,6.14,6.14,0,0,0,3.51,3.51,8.81,8.81,0,0,0,2.91.56C18.33,34,18.74,34,22,34s3.67,0,4.95-.07a8.81,8.81,0,0,0,2.91-.56,6.14,6.14,0,0,0,3.51-3.51,8.81,8.81,0,0,0,.56-2.91C34,25.67,34,25.26,34,22s0-3.67-.07-4.95a8.81,8.81,0,0,0-.56-2.91,6.14,6.14,0,0,0-3.51-3.51,8.81,8.81,0,0,0-2.91-.56C25.67,10,25.26,10,22,10Z"></path>
                  <path d="M22,15.84A6.16,6.16,0,1,0,28.16,22,6.16,6.16,0,0,0,22,15.84ZM22,26a4,4,0,1,1,4-4A4,4,0,0,1,22,26Z"></path>
                  <circle cx="28.41" cy="15.59" r="1.44"></circle>
                </g>
              </svg>
            </a>
          </div>
        </div>

        <div className={"c jc ac"}>
          <a
            href={"https://cornermarketcafe.net/"}
            style={linkStyle}
            target={"_blank"}
            rel={"noreferrer"}
            className={"mb2 px3"}
          >
            <img src={cornerMarketCafeLogo} height={100} />
          </a>
          <a
            href={"https://cornermarketcafe.net/"}
            style={linkStyle}
            target={"_blank"}
            rel={"noreferrer"}
            className={"mb2 px3"}
          >
            CORNER MARKET CAFE
          </a>
          <div className={"r mb2 jc ac mxa mt2"} id="">
            <a
              href="https://www.facebook.com/people/Corner-Market-Cafe/100063937067648/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-link"
            >
              <svg
                focusable="false"
                height="44px"
                role="presentation"
                width="44px"
                viewBox="0 0 44 44"
                className="sc-bdVaJa hhZVOl"
                type="facebook"
              >
                <circle id="bgCircle" cx="22" cy="22" r="20" />
                <path
                  data-test="facebook"
                  d="M23.34,34V23.07H27l.55-4.28H23.34V16.06c0-1.24.35-2.08,2.12-2.08h2.27V10.17A31.29,31.29,0,0,0,24.44,10C21.18,10,19,12,19,15.64v3.15H15.27v4.28H19V34Z"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/cornermarketcafe/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-link"
            >
              <svg
                focusable="false"
                height="44px"
                role="presentation"
                width="44px"
                viewBox="0 0 44 44"
                className="sc-bdVaJa hKJTVw"
                type="instagram"
              >
                <circle id="bgCircle" cx="22" cy="22" r="20"></circle>
                <g data-test="instagram">
                  <path d="M22,12.16c3.2,0,3.58,0,4.85.07a6.64,6.64,0,0,1,2.23.41,4,4,0,0,1,2.28,2.28,6.64,6.64,0,0,1,.41,2.23c.06,1.27.07,1.64.07,4.85s0,3.58-.07,4.85a6.64,6.64,0,0,1-.41,2.23,4,4,0,0,1-2.28,2.28,6.64,6.64,0,0,1-2.23.41c-1.27.06-1.64.07-4.85.07s-3.58,0-4.85-.07a6.64,6.64,0,0,1-2.23-.41,4,4,0,0,1-2.28-2.28,6.64,6.64,0,0,1-.41-2.23c-.06-1.27-.07-1.64-.07-4.85s0-3.58.07-4.85a6.64,6.64,0,0,1,.41-2.23,4,4,0,0,1,2.28-2.28,6.64,6.64,0,0,1,2.23-.41c1.27-.06,1.64-.07,4.85-.07M22,10c-3.26,0-3.67,0-4.95.07a8.81,8.81,0,0,0-2.91.56,6.14,6.14,0,0,0-3.51,3.51,8.81,8.81,0,0,0-.56,2.91C10,18.33,10,18.74,10,22s0,3.67.07,4.95a8.81,8.81,0,0,0,.56,2.91,6.14,6.14,0,0,0,3.51,3.51,8.81,8.81,0,0,0,2.91.56C18.33,34,18.74,34,22,34s3.67,0,4.95-.07a8.81,8.81,0,0,0,2.91-.56,6.14,6.14,0,0,0,3.51-3.51,8.81,8.81,0,0,0,.56-2.91C34,25.67,34,25.26,34,22s0-3.67-.07-4.95a8.81,8.81,0,0,0-.56-2.91,6.14,6.14,0,0,0-3.51-3.51,8.81,8.81,0,0,0-2.91-.56C25.67,10,25.26,10,22,10Z"></path>
                  <path d="M22,15.84A6.16,6.16,0,1,0,28.16,22,6.16,6.16,0,0,0,22,15.84ZM22,26a4,4,0,1,1,4-4A4,4,0,0,1,22,26Z"></path>
                  <circle cx="28.41" cy="15.59" r="1.44"></circle>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <PoweredByVidCruiter />
    </footer>
  );
}
