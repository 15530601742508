import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Link from "gatsby-link";
import seasonsLogo from "../../images/season-logo.png";
import cornerLogo from "../../images/Seasons-Corner-Cafe-logo.png";

export default function Header(props) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const metaDescription = props.description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const meta = {};
  let lang = "en";

  return (
    <>
      <Helmet
        bodyAttributes={{ class: props.bodyClasses }}
        htmlAttributes={{
          lang,
        }}
        title={props.title ? props.title : defaultTitle}
        titleTemplate={defaultTitle ? `%s` : null}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: props.title ? props.title : defaultTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata?.author || ``,
          },
          {
            name: `twitter:title`,
            content: props.title ? props.title : defaultTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      >
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
          integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
          crossOrigin="anonymous"
        />
      </Helmet>
      <header>
        <div className="container my4 c jc-ml jc-ml">
          <div className="w100 r-c-ml jsb jc-ml ac mwxxl mxa">
            <div className={"header-imgs-container"}>
              <Link to="/">
                <img
                  width={319}
                  height={80}
                  src={seasonsLogo}
                  alt="SeasonsLogo"
                  className="header-img"
                />
              </Link>
              <img
                width={140}
                height={140}
                src={cornerLogo}
                alt="Seasons Corner Cafe Logo"
                className="header-img"
              />
            </div>
          </div>
          {props.showBackButton ? (
            <a href="/" className="mxa-sm">
              <button className={"button"}>Back to Map</button>
            </a>
          ) : (
            ""
          )}
        </div>
      </header>
    </>
  );
}
